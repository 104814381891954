{
   "dashboard": {
    "welcome_message": "Hello, Blume!\nWelcome",
    "select_step": {
      "choose": "Choose",
      "pay": "Pay",
      "receive": "Receive"
    },
    "products": {
      "label": "Pack",
      "water_name": "Crystal",
      "bottle_count": "{count} Bottles"
    }
  },
  "pay": {
  "steps": {
    "choose": "Choose",
    "pay": "Pay",
    "receive": "Receive"
  },
  "instructions": {
   "title1": "Press and hold the QR Code to save it",
   "title2":"Then pay via the banking app",
   "title3": "Once paid, the machine will open",
   "title4": "Please take all the water packs",
   "title5":"Remaining water will be given",
   "title6": "to the property staff."
  },
  "transaction": {
    "order": "Order",
    "expire": "Please pay before"
  },
  "success_message": "Payment Successful! Redirecting to the main page.",
  "failure_message": "Payment timeout! Redirecting to the main page."
},
 "payment_instructions": {
    "title": "Pay via Bank App QR",
    "step1": "1. Save the QR image to your device",
    "step2": "2. Open your banking app",
    "step3": "3. Scan the QR code",
    "step4": "4. Select 'Pay with QR'",
    "continue": "Continue"
  },
  "purchasing_summary": {
    "choose": "Choose",
    "pay": "Pay",
    "receive": "Receive",
    "badge_text": "12 Bottles",
    "product_info": "6 bottles per pack",
    "promo_text": "Special Promo",
    "currency": "THB",
    "buy": "Buy",
    "back": "Back"
  },
 "select_merchandise": {
    "title": "Select Merchandise"
  },
  "session_error": {
    "title": "Session Error",
    "continue": "Continue"
  },
  "common": {
    "language_switch": "Switch Language",
    "back": "Back",
    "next": "Next"
  }
}
