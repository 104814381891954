{
  "dashboard": {
    "welcome_message": "สวัสดีค่ะ บลูมเม่\nยินดีต้อนรับ",
    "select_step": {
      "choose": "เลือก",
      "pay": "จ่าย",
      "receive": "รับ"
    },
    "products": {
      "label": "แพ็ค",
      "water_name": "คริสตัล",
      "bottle_count": "{count} ขวด"
    }
  },
  "pay": {
    "steps": {
      "choose": "เลือก",
      "pay": "จ่าย",
      "receive": "รับ"
    },
    "instructions": {
      "title1": "กดค้างที่ QR Code เพื่อเซฟ QR Code นี้",
      "title2": "แล้วจ่ายด้วยแอปธนาคาร",
      "title3": "จ่ายเสร็จ ตู้จะเปิดออก",
      "title4": "กรุณาหยิบแพ็คน้ำออกให้หมด",
      "title5": "น้ำที่ค้างไว้ เราขอสงวนสิทธิ์",
      "title6": "ในการยกให้เจ้าหน้าที่นิติ"
    },
    "transaction": {
      "order": "คำสั่งซื้อ",
      "expire": "กรุณาชำระเงินภายใน"
    },
    "success_message": "จ่ายเงินสำเร็จ!! ระบบกำลังพาคุณกลับไปหน้าหลัก",
    "failure_message": "หมดเวลาการจ่ายเงิน!! ระบบกำลังพาคุณกลับไปหน้าหลัก"
  },
  "payment_instructions": {
    "title": "จ่าย QR ในแอพธนาคาร",
    "step1": "1. เซฟรูป QR ลงเครื่อง",
    "step2": "2. เปิดแอพธนาคาร",
    "step3": "3. เปิดใหม่หมด scan จ่าย",
    "step4": "4. เลือกจ่ายด้วยรูป QR",
    "continue": "ดำเนินการต่อ"
  },
  "purchasing_summary": {
    "choose": "เลือก",
    "pay": "จ่าย",
    "receive": "รับ",
    "badge_text": "12 ขวด",
    "product_info": "แพคละ 6 ขวด",
    "promo_text": "โปรโมชั่นสุดคุ้ม",
    "currency": "บาท",
    "buy": "ซื้อ",
    "back": "กลับ"
  },
  "select_merchandise": {
    "title": "เลือกสินค้า"
  },
  "session_error": {
    "title": "เกิดข้อผิดพลาดในเซสชัน",
    "continue": "ดำเนินการต่อ"
  },
  "common": {
    "language_switch": "สลับภาษา",
    "back": "ย้อนกลับ",
    "next": "ถัดไป"
  }
}
